<template>
  <div>
    <GraphsMenu menu="generics-branches"></GraphsMenu><br />
    <CCard>
      <CCardHeader>
        <slot name="header">
          Marca/Sucursales Rezagados
          <div class="card-header-actions" style="width: 600px; margin-right: 18px;">
            <CRow>      
              <CCol md="3" style="text-align: right;">
              </CCol>
              <CCol md="4" style="text-align: right;">
                <CSelect class="m-0"
                  :value.sync="monthSelect" 
                  :options="monthsOptions"
                  @change="changeFilters"
                />  
              </CCol>
              <CCol md="5" style="text-align: right; padding-right: 0px;">
                <CSelect class="m-0" style="float: right; width: 100%;"
                  :value.sync="genericSelect" 
                  :options="genericsOptions"
                  @change="changeFilters"
                />   
              </CCol>       
            </CRow>
          </div>
        </slot>
      </CCardHeader>
      <CCardBody>
        <CRow>      
          <CCol md="12">
            <CDataTable 
              :hover="true"
              :striped="true"
              :border="true"
              :small="true"
              :fixed="true"
              :items="branchs"
              :fields="fields"
              :items-per-page="20"
              :dark="false"
              pagination
              style="width: 100%;"
            >
              <template #customer_name="{item}"><td>{{item.customer_name}}</td></template>
              <template #name="{item}"><td>{{item.branch_name}}</td></template>
              <template #person_name="{item}"><td>{{item.person_name}}</td></template>
              <template #person_salary="{item}">
                <td class="text-center w100">
                  {{(item.person_salary) > 0 ?"$"+item.person_salary : ""}}
                </td>
              </template>
              <template #total="{item}">
                <td class="text-center w50">
                  {{item.total}}
                </td>
              </template>
              <template #done="{item}">
                <td class="text-center w50">
                  {{item.done}}
                </td>
              </template>
              <template #validated="{item}">
                <td class="text-center w50">
                  {{item.validated}}
                </td>
              </template>
            </CDataTable>
          </CCol>     
        </CRow>
      </CCardBody>
    </CCard>
  </div>
</template>
<script>

import store from '../../store'
import ws from '../../services/graphs';
import GraphsMenu from './GraphsMenu.vue';

export default {
  name: 'GenericsBranches',
  components: {    
    GraphsMenu
  },
  data () {
    return {
      code: 'general',
      branchs: [],
      fields: [
          {key: 'customer_name', label: 'Marca'},
          {key: 'branch_name', label: 'Sucursal'},
          {key: 'person_name', label: 'Rezagador'},
          {key: 'person_salary', label: 'Salario' ,_style:" width: 150px;"},
          {key: 'total', label: 'Total Visitas' ,_style:" width: 50px;"},
          {key: 'done', label: 'Visitas Terminadas' ,_style:" width: 50px;"},
          {key: 'validated', label: 'Visitas Validadas' ,_style:" width: 50px;"},
        ],
      
      genericSelect: "",
      genericsOptions: [],
      monthSelect: "202409",
      monthsOptions: [],
      dataGraphs: [],
      graphs_gauge: 0,
      semaforo: 0,
      graphs_percent:0,
      graphs_donout:[{code: '',service : "",total : "0",}],
      metrics:{
        total : "0",
        finished : "0",
        finished_percent: "0",
        finished_kam : "0",
        finished_kam_percent: "0",
      }
    }
  },
  mounted: async function() {
    this.loading();

    this.code = await this.$route.params.code;

    let is_administrator = localStorage.getItem("is_administrator");

    if(is_administrator == "false"){
      let is_kam = localStorage.getItem("is_kam");

      if(is_kam == "false"){
        window.location.href = "/#/home"; this.loaded(); return;
      }
      else{
        window.location.href = "/#/welcome"; this.loaded(); return;
      }
    }

    let response = await ws.getGenerics(); 
    if(response.type == "success"){
      this.genericsOptions = await this.$parseSelectOptions(response.data, "id", "name",[{"label": "Todos","value":""}]);
      if(response.data.length > 0){
        this.genericSelect = this.genericsOptions[0].value;
      }      
    }

    let response2 = await ws.getMonths(); 
    if(response2.type == "success"){
      this.monthsOptions = await this.$parseSelectOptions(response2.data, "id", "name");
      if(response2.data.length > 0){
        this.monthSelect = this.monthsOptions[0].value;
      }      
    }

    await this.changeFilters();

    this.loaded();
  },
  methods: {
    async changeFilters () {
      this.loading();
        let filters = {
          collaborator_id : this.genericSelect,
          month : this.monthSelect,
        };       
        let response = await ws.getGenericsBranches(filters);
        if(response.type == "success"){
          this.branchs = response.data.branchs;        
        }
      this.loaded();
    },
    showToast(type, message){
        store.commit('toast', {color: type, message: message});
    },
    loading(){
      store.commit('loading');
    },
    loaded(){
      store.commit('loaded');
    }
  }
}
</script>

<style>
.warning{
 background-color: #F9B115;
}
.success{
  background-color: #1B9E3E;
}
.danger{
  background-color: #E55353
}
.other{
  background-color: #6b7785
}
.card.success{
  color:black !important;
  font-weight: bold !important;
}
.card-header-actions select{
  font-size: 12px;
}
.card-header{
  font-size: 20px;
  padding: 10px 15px 10px 20px;

}
</style>
